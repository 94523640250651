<template>
  <a-card :bordered="false">
    <a-form layout="inline">
      <a-form-item label="操作员">
        <a-input allow-clear v-model="queryParam.username" placeholder="请输入操作员"/>
      </a-form-item>
      <a-form-item label="类型">
        <a-select allow-clear v-model="queryParam.type" style="min-width:100px" :default-value="0" placeholder="请选择">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">客户</a-select-option>
          <a-select-option :value="2">待确认</a-select-option>
          <a-select-option :value="3">更新</a-select-option>
          <a-select-option :value="4"> 完成</a-select-option>
          <a-select-option :value="5">取消</a-select-option>
          <a-select-option :value="6">创建</a-select-option>
          <a-select-option :value="7">下载</a-select-option>
          <a-select-option :value="8">预约</a-select-option>
        </a-select>

      </a-form-item>
      <a-form-item label="开始时间">
        <a-date-picker
          show-time
          v-model="queryParam.startTime"
          format="YYYY-MM-DD"
          placeholder="结束时间"
        />
      </a-form-item>
      <a-form-item label="结束时间">
        <a-date-picker
          show-time
          v-model="queryParam.endTime"
          format="YYYY-MM-DD"
          placeholder="结束时间"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="$refs.table.refresh(true);onClearSelected()">查询</a-button>
      </a-form-item>
      <a-form-item>
        <a-button style="margin-left: 8px" @click="queryParam=>{}">重置</a-button>
      </a-form-item>
      <a-form-item>
        <a-button style="margin-left: 8px" @click="download">下载</a-button>
      </a-form-item>
    </a-form>
    <s-table
      ref="table"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 900,y: 600 }"
      bordered
      rowKey="key"
      size="default"
    >
    </s-table>
  </a-card>
</template>

<script>
import tree from 'vue-giant-tree'
import { STable } from '@/components'
import { FetchList, LogDownload } from '@/api/log'
import formCreate from './from'
import { formatterTime } from '@/utils/util'
import moment from 'moment'

export default {
  components: {
    STable,
    formCreate,
    tree
  },
  data () {
    return {
      parentId: 0,
      check: {
        enable: true
      },
      selectedRowKeys: [],
      selectedRows: [],
      visible: false,
      confirmLoading: false,
      loading: false,
      title: '',
      mdl: null,
      // 查询参数
      queryParam: {},
      orgTree: [],
      // 表头
      columns: [
        {
          title: 'Id',
          dataIndex: 'id',
          sorter: true
        },
        {
          title: '操作员',
          dataIndex: 'username'
        },
        {
          title: 'ip',
          dataIndex: 'ip'
        },
        {
          title: '操作内容',
          dataIndex: 'content'
        },
        {
          title: '操作类型',
          dataIndex: 'type',
          customRender: (val) => {
            if (val === 1) {
              return '用户'
            }
            if (val === 2) {
              return '待确认'
            }
            if (val === 3) {
              return '更新'
            }
            if (val === 4) {
              return '完成'
            }
            if (val === 5) {
              return '取消'
            }
            if (val === 6) {
              return '创建'
            }
            if (val === 7) {
              return '创建'
            }
            if (val === 8) {
              return '预约'
            }
          }
        },
        {
          title: '操作时间',
          align: 'center',
          dataIndex: 'createTime',
          sorter: true,
          customRender: (val) => {
            return formatterTime(val)
          }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        var searchParam = []
        searchParam = this.getCommonOperate(searchParam, ['=__status', '=__type', 'like__username'])
        if (this.queryParam.startTime != null) {
          var startTime = moment(moment(this.queryParam.startTime).format('YYYY-MM-DD') + ' 00:00:00').valueOf()
          searchParam['>=__createTime'] = startTime
        }
        if (this.queryParam.endTime != null) {
          var endTime = moment(moment(this.queryParam.endTime).format('YYYY-MM-DD') + ' 00:00:00').valueOf()
          searchParam['<=__createTime'] = endTime
        }
        return FetchList(Object.assign(parameter, searchParam))
          .then(res => {
            return res.result
          })
      }
    }
  },
  methods: {
    getCommonOperate (searchParam, fileds) {
      for (var key in this.queryParam) {
        var value = this.queryParam[key]
        if (typeof value !== 'number') {
          if (value === undefined || value === '' || value === null) {
            continue
          }
          value = value.toString().trim()
          if (value.length === 0) continue
        } else {
          if (value === 0) {
            continue
          }
        }
        for (var j = 0; j < fileds.length; j++) {
          var array = fileds[j].split('__')
          if (array[1] === key) {
            searchParam[fileds[j]] = value
          }
        }
      }
      return searchParam
    },
    download () {
      const parameter = {}
      var searchParam = []
      searchParam = this.getCommonOperate(searchParam, ['=__status', '=__type', 'like__username'])
      if (this.queryParam.startTime != null) {
        var startTime = moment(moment(this.queryParam.startTime).format('YYYY-MM-DD') + ' 00:00:00').valueOf()
        searchParam['>=__createTime'] = startTime
      }
      if (this.queryParam.endTime != null) {
        var endTime = moment(moment(this.queryParam.endTime).format('YYYY-MM-DD') + ' 00:00:00').valueOf()
        searchParam['<=__createTime'] = endTime
      }
      LogDownload(Object.assign(parameter, searchParam)).then(res => {
        const fileName = '日志下载.xls'
        const content = res
        const blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' })
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    }
  }
}
</script>
