import request from '@/utils/request'

const api = {
  user: '/admincp/log/index',
  Download: '/admincp/log/download'
}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.user,
    method: 'post',
    data: parameter
  })
}
export function LogDownload (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.Download,
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}
